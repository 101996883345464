import Vue from "vue";
import vuex from "vuex";
Vue.use(vuex);
export default new vuex.Store({
  state: {
    access_token: window.localStorage.getItem("access_token"),
    get_info: window.localStorage.getItem("get_info"),
    total: window.localStorage.getItem("total"),
  },
  mutations: {
    LOGIN: (state, data) => {
      //更改token的值
      state.access_token = data;
      // console.log(data)
      window.localStorage.setItem("access_token", data);
    },
    getcategoryName: (state, data) => {
      state.get_info = data;
      window.localStorage.setItem("get_info", JSON.stringify(data));
    },
    total: (state, data) => {
      state.total = data;
      window.localStorage.setItem("total", JSON.stringify(data));
    },
  },
});
