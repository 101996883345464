/*
 * @Author: your name
 * @Date: 2021-03-03 09:57:57
 * @LastEditTime: 2021-05-10 21:02:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \work\src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";


import VCharts from 'v-charts';
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入echarts
import echarts from 'echarts'
import Mint from 'mint-ui';
import { get_info } from "./api/api";

Vue.use(Mint);


Vue.use(Vant);
Vue.use(VCharts)
Vue.use(ElementUI);


Vue.config.productionTip = false;
Vue.prototype.$aox = axios; //放入全局

Vue.prototype.$echarts = echarts

// 路由守卫
/*router.beforeEach((to, from, next) => {
	if (to.meta.requiresAuth === false) {
		//不需要登录的直接放行
		next()
	} else {
		const isLogin = localStorage.access_token ? true : false;
		console.log(to.path);
		if (to.path == "/") {
			next();
		} else {
			// 是否在登录状态下
			isLogin ? next('') : next("/");
		}
	}

})*/

/*router.beforeEach((to, from, next) => {
	if (to.meta.requiresAuth === false) {
		//不需要登录的直接放行
		next()
	} else {
		const isLogin = localStorage.access_token ? true : false;
		console.log(to.path);
		if(isLogin){
				next()
		}else{
			next("/")
		}
	
	}

})*/

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
