/*
 * @Author: your name
 * @Date: 2021-03-03 09:57:57
 * @LastEditTime: 2021-06-02 15:29:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \work\src\api\api.js
 */
import {
	post,
	get
} from "@/api/http.js";

export function login(params) {
	return post("/bmp/v2/customized/user/login", params); //登录
}
export function get_info(params) {
	return post("/bmp/v2/customized/user/get_info", params); //获取登录信息
}
export function list(params) {
	return get("/bmp/v2/customized/info/mobile/list", params); //获取点歌机信息列表
}
export function index(params) {
	return get("/bmp/v2/customized/info/payment/index/new", params); //获取账单流水信息列表	/bmp/v2/customized/info/payment/index
}
export function settlement(params) {
	return post("/bmp/v2/customized/payment/settlement", params); //申请结算
}
export function song(params) {
	return get("/bmp/v2/statistics/song", params); //歌曲下载数统计
}
export function action(params) {
	return get("/bmp/v2/statistics/action", params); //机器开机数统计
}
export function mac_register(params) {
	return get("/bmp/v2/statistics/mac_register", params); //统计客户机型机器注册激活数据
}
export function modelShuttle(params) {
	return get("/bmp/v2/customized/factorypackage/modelShuttle", params); //获取机型树形结构表
}
export function userss(params) {
	return post("/bmp/v2/customized/register/user", params); //注册信息填写
}
export function code(params) {
	return post("/bmp/v2/customized/send/verification/code", params); //获取手机短信验证码
}
export function logins(params) {
	return post("/bmp/v2/customized/user/mobile/login", params); //手机号短信登录
}
export function mobiles(params) {
	return post("/bmp/v2/customized/user/binding/mobile", params); //绑定手机号
}
export function songname(params) {
	return post("/bmp/v2/customized/get/category/songname", params); //获取该用户的歌单组
}
export function list_group(params) {
	return get("/bmp/v2/customized/song/list_group", params); //获取全部歌单组
}
export function speeds(params) {
	return post("/bmp/v2/customized/set/category/speed", params); //设置机型下载速度
}
export function song_group(params) {
	return post("/bmp/v2/customized/set/user/song_group", params); //设置机型的歌单组
}
export function song_groups(params) {
	return post("/bmp/v2/customized/set/user/mac/song_group", params); //设置机器指定使用的歌单组
}
export function deletes(params) {
	return post("/bmp/v2/customized/set/user/mac/song_group/delete", params); //删除机器MAC指定歌单组
}
export function into(params) {
	return get("/bmp/v2/customized/month/payment/into", params); //获取月度账单分成流水
}
export function sellte_index(params) {
	return get("/bmp/v2/customized/payment/sellte/index", params); //获取账户申请结算历史记录
}
export function settlement_into(params) {
	return post("/bmp/v2/customized/payment/settlement/into", params); //删除机器MAC指定歌单组
}
export function order_info(params) {
	return get("/bmp/v2/customized/info/payment/order/info", params); //账单详情
}

export function opening_vip_list(params) {
	return get("/bmp/v2/customized/opening/vip/list", params); //VIP自主开通记录
}
export function opening_vip_add(params) {
	return post("/bmp/v2/customized/opening/vip/add", params); //设置开通机器VIP
}

export function update_status_mac(params) {
	return post("/bmp/v2/customized/status/mac", params); //禁用启用点歌机
}