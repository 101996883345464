<template>
  <div class="navBar">
    <van-nav-bar
      :title="title"
      :left-arrow="
        $route.meta.title != '账单查询' &&
        $route.meta.title != '设备' &&
        $route.meta.title != '统计'
      "
      @click-left="onClickLeft"
    />
  </div>
</template>

<script>
export default {
  name: "navBar",
  data() {
    return {
      title: "",
      to: {},
    };
  },
  mounted() {
    this.title = this.$route.meta.title;
  },
  watch: {
    $route(to) {
      this.to = to;
      this.title = this.to.meta.title;
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.navBar {
  /* position: fixed;
  top: 0; */
  width: 100%;
  background-color: white;
  /* height: 5rem; */
  z-index: 0;
}
</style>