import Vue from "vue";
import Router from "vue-router";
import Login from "@/pages/site/Login";

Vue.use(Router);

const router = new Router({
  mode: "history", //hash history
  base: process.env.BASE_URL,
  routes: [

    {
      path: "/",
      name: "login",
      component: Login,
      meta: {
        requiresAuth: false
      },
    },

    //注册
    {
      path: "/justlink/register",
      name: "register",
      component: (resolve) => require(["@/pages/register/register"], resolve),
      meta: {
        requiresAuth: true
      },
    },

    //账单
    {
      path: "/justlink/bill",
      name: "bill",
      component: (resolve) => require(["@/pages/home/bill"], resolve),
      meta: {
        requiresAuth: true,
      },
    },

    //管理员
    {
      path: "/justlinks/Superadmin",
      name: "Admin",
      component: (resolve) => require(["@/pages/Admin/Admin"], resolve),
      meta: {
        requiresAuth: true,
        name: "bill"
      },
    },

    //绑定手机号
    {
      path: "/justlink/binding",
      name: "binding",
      component: (resolve) => require(["@/pages/binding/binding"], resolve),
      meta: {
        requiresAuth: true
      },
    },
    //测速
    {
      path: "/justlink/velocityMeasurement",
      name: "velocityMeasurement",
      component: (resolve) => require(["@/pages/velocityMeasurement/velocityMeasurement"], resolve),
      meta: {
        requiresAuth: false
      },
    },
        //测速
        {
          path: "/justlink/velocityMeasurementURL",
          name: "velocityMeasurementURL",
          component: (resolve) => require(["@/pages/velocityMeasurement/velocityMeasurementURL"], resolve),
          meta: {
            requiresAuth: false
          },
        },

    // {
    //   path: "/justlink/"+b64Encode('/justlink/equipment'),
    //   name: "billone",
    //   component: (resolve) => require(["@/pages/home/billone"], resolve),
    //   meta: { title: "账单查询", name: "bill" },
    // },
    //账单test
    //  {
    //   path: "/justlink/datatime",
    //   name: "datatime",
    //   component: (resolve) => require(["@/pages/datatime/datatime"], resolve),
    //   meta: { title: "选择时间", name: "bill" },
    // },
    //设备
    {
      path: '/justlink/equipment',
      name: "equipment",
      component: (resolve) => require(["@/pages/equipment/equipment"], resolve),
      meta: {
        requiresAuth: true,
        title: "设备",
        name: "equipment"
      },
    },

    // // 统计test
    // {
    //   path: "/justlink/statisticsone",
    //   name: "statistics2",
    //   component: (resolve) =>
    //     require(["@/pages/statistics/statisticsone"], resolve),
    //   meta: { requiresAuth: true, title: "统计", name: "Statistics" },
    // },

    //统计
    {
      path: "/justlink/statistics",
      name: "statistics",
      component: (resolve) =>
        require(["@/pages/statistics/statistics"], resolve),
      meta: {
        requiresAuth: true,
        title: "统计",
        name: "Statistics"
      },
    },
    //每月收益
    {
      path: "/justlink/monthlyIncome",
      name: "monthlyIncome",
      component: (resolve) => require(["@/pages/monthlyIncome/monthlyIncome"], resolve),
      meta: {
        requiresAuth: true,
        title: "每月收益",
        name: "mine"
      },
    },
    //我的
    {
      path: "/justlink/mine",
      name: "mine",
      component: (resolve) => require(["@/pages/mine/mine"], resolve),
      meta: {
        requiresAuth: true,
        title: "我的",
        name: "mine"
      },
    },
    //修改密码
    {
      path: "/justlink/ChangePassword",
      name: "ChangePassword",
      component: (resolve) =>
        require(["@/pages/ChangePassword/ChangePassword"], resolve),
      meta: {
        title: "修改密码",
        name: "mine"
      },
    },
    //申请结算
    {
      path: "/justlink/settlement",
      name: "settlement",
      component: (resolve) =>
        require(["@/pages/settlement/settlement"], resolve),
      meta: {
        requiresAuth: true,
        title: "申请结算",
        name: "mine"
      },
    },
    //机型管理
    {
      path: "/justlink/Administration",
      name: "Administration",
      component: (resolve) =>
        require(["@/pages/Administration/Administration"], resolve),
      meta: {
        requiresAuth: true,
        title: "机型管理",
        name: "mine"
      },
    },
    //批量设置歌单组
    {
      path: "/justlink/batchSettings",
      name: "batchSettings",
      component: (resolve) =>
        require(["@/pages/batchSettings/batchSettings"], resolve),
      meta: {
        requiresAuth: true,
        title: "批量设置歌单组",
        name: "mine"
      },
    },
    //下载
    {
      path: "/justlink/download",
      name: "download",
      component: (resolve) =>
        require(["@/pages/download/download"], resolve),
      meta: {
        requiresAuth: true,
        title: "下载数量",
        name: "download"
      },
    },
    //开通会员
    {
      path: "/justlink/addMacVip",
      name: "addMacVip",
      component: (resolve) =>
        require(["@/pages/vip/addMacVip"], resolve),
      meta: {
        requiresAuth: true,
        title: "一键开通会员",
        name: "addMacVip"
      },
    },

    {
      path: "/justlink/WorldMap",
      name: "WorldMap",
      component: (resolve) =>
        require(["@/pages/WorldMap/WorldMap"], resolve),
      meta: {
        requiresAuth: false
      },
    },
    {
      path: "/justlink/tests",
      name: "WorldMap",
      component: (resolve) =>
        require(["@/views/test/view"], resolve),
      meta: {
        requiresAuth: false
      },
    },

    {
      path: "*",
      name: "404",
      component: (resolve) =>
        require(["@/pages/404/index"], resolve),
      meta: {
        requiresAuth: true,
        title: "404 Not Found"
      },
    },
  ],
});


function generateRouteByPermision(routes, permission) {
  let filter_result = [];
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].children && routes[i].children.length > 0) {
      let children_result = generateRouteByPermision(routes[i].children, permission)
      if (children_result.length > 0) {
        routes[i].children = children_result
        filter_result.push(routes[i])
      } else {
        routes[i].children = []
        if (routes[i].meta.noPermission || permission.indexOf(routes[i].path) > -1) {
          filter_result.push(routes[i])
        }
      }
    } else {
      if (routes[i].meta.noPermission || permission.indexOf(routes[i].path) > -1) {
        filter_result.push(routes[i])
      }
    }
  }

  if (permission[0] !== routes[0].redirect) {
    // console.log(permission[0],routes[0].redirect)
    routes[0].redirect = permission[0]
    //   console.log( routes[0].redirect)
  }

  return filter_result
}

// function b64Encode(str) {
//   return btoa(encodeURIComponent(str));
// }

export default router;