<template>
  <div id="app">
    <nav-bar v-if="$route.meta.requiresAuth"></nav-bar>
    <router-view v-if="isRouterAlive"></router-view>
    <tab-bar v-if="$route.meta.requiresAuth"></tab-bar>
  </div>
  <!-- !(path === '/') &&
        !(path === '/justlink/register') &&
        !(path === '/justlink/binding') &&
        !(path === '/justlinks/Superadmin')&&
        !(path === '/justlink/WorldMap') -->
</template>

<script>
import navBar from "./components/navBar/navBar.vue";
import tabBar from "./components/tabbar/tabbar.vue";

export default {
  name: "App",
  data() {
    return {
      path: "",
      to: {},
      isRouterAlive: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  components: {
    tabBar,
    navBar,
  },
  // 判断路由
  mounted() {
    this.path = this.$route.path;
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("get_info")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("get_info"))
        )
      );
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("get_info", JSON.stringify(this.$store.state));
    });

    let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    {
      if (isiOS) {
        //在页面刷新时将vuex里的信息保存到缓存里
        window.addEventListener("pagehide", () => {
          sessionStorage.setItem("get_info", JSON.stringify(this.$store.state));
        });
        //在页面加载时读取localStorage里的状态信息
        sessionStorage.getItem("get_info") &&
          this.$store.replaceState(
            Object.assign(
              this.$store.state,
              JSON.parse(sessionStorage.getItem("get_info"))
            )
          );
      }
    }
  },
  watch: {
    $route(to) {
      this.to = to;
      this.path = this.to.path;
    },
  },
  methods: {
    b64Encode(str) {
      return btoa(encodeURIComponent(str));
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>


<style>
@import url("./assets/css/all.css");
.van-cell {
  /* font-size: 14px !important; */
  font-weight: bold;
  white-space: nowrap;
}
.van-button--normal {
  padding: 0px 12px !important;
  font-size: 12px;
}
.textName .el-input {
  font-size: 1.2rem;
}
.van-cell__value {
  font-size: 1.2rem;
}
@media screen and (min-height: 1024px) {
  .equipment-bottom .equipment-bottom-col {
    padding: 3rem 0rem !important;
  }
  .vip {
    top: 3.2rem !important;
  }
}
html,
body,
#app {
  height: 100%;
  overflow: auto;
}
html {
  font-size: 10px;
}
#app {
  margin: 0px auto;
  padding: 0px;
}
.el-autocomplete-suggestion li {
  /* padding: 0 10px !important; */
  font-size: 1.2rem !important;
  overflow: Visible !important;
  text-overflow: normal !important;
}
.el-popper {
  transform-origin: center top !important;
  z-index: 2021 !important;
  width: 245px !important;
  position: fixed !important;
  /* top: 254px!important; */
  left: 60px !important;
}
</style>
