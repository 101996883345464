<!--
 * @Author: your name
 * @Date: 2021-05-27 15:27:00
 * @LastEditTime: 2021-09-02 11:59:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \work\src\components\tabbar\tabbar.vue
-->
<template>
  <div id="footer">
    <van-tabbar v-model="active" @change="onChange">
       <van-tabbar-item name="bill" icon="records"  route to="/justlink/bill" v-if="$store.state.get_info.menu !== '0'">账单
      </van-tabbar-item>
      <van-tabbar-item name="equipment"  route icon="search" to="/justlink/equipment">设备</van-tabbar-item>
      <van-tabbar-item name="Statistics" icon="friends-o" to="/justlink/statistics" v-if="$store.state.get_info.menu !== '0' ">统计</van-tabbar-item>
      <van-tabbar-item name="download" icon="down" to="/justlink/download"
        v-if="$store.state.get_info.uuid == 3513081583010816">下载</van-tabbar-item>
      <van-tabbar-item name="mine" icon="contact" to="/justlink/mine">我</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "tabBar",
  data() {
    return {
      active: "",
      to: {},
    };
  },
  mounted() {
     console.log( this.$store.state.get_info.menu);

 
    //
    //
    // let b = this.b64Encode('/justlink/mine');
    //  let a = this.b64Decode(b);
    // console.log(a);
    // console.log(b)
    // this.b64Decode('/justlink/bill')
    this.active = this.$route.meta.name;
  },
  watch: {
    $route(to) {
      this.to = to;
      this.active = this.to.meta.name;
    },
  },
  methods: {
    onChange(index) {
      console.log(index);
    },
    b64Encode(str) {
      return btoa(encodeURIComponent(str));
    },
    // b64Decode(str) {
    //   return decodeURIComponent(atob(str));
    // },
  },
};
</script>

<style scoped>
</style>