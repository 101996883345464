<template>
  <div class="login" id="login">
    <div class="title">
      <div class="h1">欢迎使用! 定制客户管理平台</div>
    </div>
    <div class="login-border" v-show="bool === 'admin'">
      <div class="login-border-line">
        <van-field
          v-model="form.admin"
          label="用户名"
          placeholder="请输入账户名称"
        />
      </div>
      <div class="login-border-line">
        <van-field
          v-model="form.password"
          type="password"
          label="密码"
          placeholder="请输入密码"
        />
      </div>
    </div>
    <div class="login-border" v-show="bool === 'yzm'">
      <div class="login-border-line">
        <van-field
          v-model="form.phone"
          type="tel"
          label="手机号"
          placeholder="请输入手机号"
        />
      </div>
      <div class="login-border-line">
        <van-field
          v-model="form.yzm"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              color="rgb(0,122,225)"
              :loading-text="time"
              :loading="loading"
              :disabled="loading"
              :loading-size="size"
              @click="yz"
              >发送验证码</van-button
            >
          </template>
        </van-field>
      </div>
    </div>
    <div class="font">
      <div v-show="bool === 'yzm'" @click="admin">账号登录</div>
      <div v-show="bool === 'admin'" @click="yzm">验证码登录</div>
    </div>
    <div class="login-border-line">
      <div class="button" @click="buttom">
        <div class="login-button">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import { login, get_info, code, logins } from "@/api/api.js";
export default {
  data() {
    return {
      abc:"mine",
      codes: "",
      size: "0",
      time: "60",
      loading: false,
      bool: "admin",
      form: {
        admin: "",
        password: "",
        phone: "",
        yzm: "",
      },
    };
  },
  mounted() {
    document.getElementById("login").style =
      "height:" + window.screen.availHeight + "px";
  },
  methods: {
    logins(mobile, code) {
      let post_data = {
        mobile: mobile,
        code: code,
      };
      logins(post_data)
        .then((res) => {
          Toast(res.result_message);
          console.log(res);
          if (res.binding !== undefined) {
            Dialog.confirm({
              message: "该手机号存在未绑定账号,是否绑定！",
            })
              .then(() => {
                this.$router.push({
                  name: "binding",
                });
              })
              .catch(() => {
                this.bool = "admin";
              });
          } else {
            this.$store.commit("LOGIN", res.data.token);
            new Promise((val) => {
              let post_data2 = {};
              get_info(post_data2)
                .then((res) => {
                  console.log(res.data);
                  this.$store.commit("getcategoryName", res.data);
                  val(res);
                })
                .catch((err) => {
                  console.log(err);
                });
            }).then((val2) => {
              this.$router.push({
                name: "bill",
              });
            });
          }
          // if(res.result_code === 0){
          // 	Toast(res.result_message)
          // }else{
          // 	if (res.binding !== undefined) {
          // 		Dialog.confirm({
          // 				message: "该手机号存在未绑定账号,是否绑定！",
          // 			})
          // 			.then(() => {
          // 				this.$router.push({
          // 					name: "binding",
          // 				});
          // 			})
          // 			.catch(() => {
          // 				this.bool = "admin";
          // 			});
          // 	} else {
          // 		this.$store.commit("LOGIN", res.data.token);
          // 		new Promise((val)=>{

          // 			let post_data2 = {};
          // 			get_info(post_data2)
          // 				.then((res) => {
          // 					console.log(res.data);
          // 					this.$store.commit("getcategoryName", res.data);
          // 					val(res)
          // 				})
          // 				.catch((err) => {
          // 					console.log(err);
          // 				});
          // 		}).then((val2)=>{
          // 			this.$router.push({
          // 				name: "bill",
          // 			});
          // 		})
          // 	}
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    yz() {
      var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/; //手机号正则验证
      var phoneNum = this.form.phone;
      if (!phoneNum) {
        //未输入手机号
        Toast("请输入手机号码");
        return;
      }
      if (!reg.test(phoneNum)) {
        //手机号不合法
        Toast("您输入的手机号码不正确，请重新输入");
      } else {
        this.code(phoneNum);
        this.loading = true;
        let test = setInterval(() => {
          this.time = (this.time - 1).toString();
          if (this.time == 0) {
            clearInterval(test);
            this.loading = false;
            this.time = "60";
          }
        }, 1000);
      }
    },
    admin() {
      this.bool = "admin";
    },
    yzm() {
      this.bool = "yzm";
    },
    code(mobile) {
      let post_data = {
        mobile: mobile,
      };
      code(post_data)
        .then((res) => {
          // if(res.result_code)
          Toast(res.result_message);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    login(user, password) {
      let post_data = {
        user: user,
        password: password,
      };
      login(post_data)
        .then((res) => {
          if (res.result_code === 0) {
            Toast(res.result_message);
          } else {
            //保存token
            this.$store.commit("LOGIN", res.data.token);
            // this.api();
            new Promise((val) => {
              let post_data2 = {};
              get_info(post_data2)
                .then((res) => {
                  console.log(res.data);
                  this.$store.commit("getcategoryName", res.data);
                  val(res);
                })
                .catch((err) => {
                  val(err);
                  console.log(err);
                });
            }).then((val2) => {
              this.$router.push({
                name: "equipment"
              });
            });
            // this.$router.push({
            // 	name: "bill",
            // });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // api() {
    // 	let post_data2 = {};
    // 	get_info(post_data2)
    // 		.then((res) => {
    // 			console.log(res.data);
    // 			this.$store.commit("getcategoryName", res.data);
    // 			val(res)
    // 		})
    // 		.catch((err) => {
    // 			val(err)
    // 			console.log(err);
    // 		});
    // },
    buttom() {
      if (this.bool === "admin") {
        console.log(123);
        if (this.form.admin === "") {
          Toast("请输入账号!");
        } else {
          if (this.form.password === "") {
            Toast("请输入密码!");
          } else {
            this.login(this.form.admin, this.form.password);
          }
        }
      } else {
        if (this.form.phone == "") {
          Toast("电话号码不能为空");
        } else {
          this.logins(this.form.phone, this.form.yzm);
        }
      }
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
}

.title {
  width: 95%;
  margin: 0px auto;
  /* height: 25vh; */
  text-align: center;
}

.h1 {
  font-size: 2rem;
  font-weight: bold;
  color: rgba(16, 16, 16, 100);
  font-family: Arial;
  line-height: 230px;
}

.login-border {
  width: 95%;
  /* height: 70vh; */
  margin: 0px auto;
  /* border: 1px solid; */
}

.login-border-line {
  width: 90%;
  height: 50px;
  margin: 1rem auto;
  /* background-color: red; */
}

.content {
  border: none;
  background-color: rgba(248, 248, 248, 100);
  color: rgba(136, 136, 136, 100);
  border-radius: 0.5rem;
  width: 84%;
  font-size: 1.4rem;
  text-align: left;
  font-family: Microsoft Yahei;
  height: 35px;
  margin-left: 0.5rem;
}

.input {
  font-size: 1.4rem;
  color: rgba(16, 16, 16, 100);
  text-align: center;
  font-family: Arial;
}

.login-border > .login-border-line {
  margin: 4rem auto;
}

.button {
  border-radius: 0.4rem;
  background-color: rgba(0, 122, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 1.6rem;
  text-align: center;
  font-family: Microsoft Yahei;
  height: 4rem;

  /* width: 10rem;
  margin: 0rem auto; */
}

.login-button {
  line-height: 4rem;
}

.font div {
  text-align: right;
  width: 95%;
  text-decoration: underline;
}
</style>
